
// Generated by generateContentBrowserItems.js

/* eslint-disable import/no-anonymous-default-export */
export default {
  "Banner_01": {
    "path": "/content-browser/item/Banner_01.glb",
    "thumbnail": "/content-browser/item/thumbnails/Banner_01.png",
    "displayName": "Banner 01",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Lightpost_01": {
    "path": "/content-browser/item/Lightpost_01.glb",
    "thumbnail": "/content-browser/item/thumbnails/Lightpost_01.png",
    "displayName": "Lightpost 01",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Shield_Green_01": {
    "path": "/content-browser/item/Shield_Green_01.glb",
    "thumbnail": "/content-browser/item/thumbnails/Shield_Green_01.png",
    "displayName": "Shield Green 01",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Shield_Red_01": {
    "path": "/content-browser/item/Shield_Red_01.glb",
    "thumbnail": "/content-browser/item/thumbnails/Shield_Red_01.png",
    "displayName": "Shield Red 01",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Stairs_Stone_01": {
    "path": "/content-browser/item/Stairs_Stone_01.glb",
    "thumbnail": "/content-browser/item/thumbnails/Stairs_Stone_01.png",
    "displayName": "Stairs Stone 01",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Stairs_Wood_01": {
    "path": "/content-browser/item/Stairs_Wood_01.glb",
    "thumbnail": "/content-browser/item/thumbnails/Stairs_Wood_01.png",
    "displayName": "Stairs Wood 01",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Tree_01": {
    "path": "/content-browser/item/Tree_01.glb",
    "thumbnail": "/content-browser/item/thumbnails/Tree_01.png",
    "displayName": "Tree 01",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Railing_01": {
    "path": "/content-browser/item/Wood_Railing_01.glb",
    "thumbnail": "/content-browser/item/thumbnails/Wood_Railing_01.png",
    "displayName": "Wood Railing 01",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Triangle_01": {
    "path": "/content-browser/item/Wood_Triangle_01.glb",
    "thumbnail": "/content-browser/item/thumbnails/Wood_Triangle_01.png",
    "displayName": "Wood Triangle 01",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "ambulance": {
    "path": "/content-browser/item/ambulance.glb",
    "thumbnail": "/content-browser/item/thumbnails/ambulance.png",
    "displayName": "Ambulance",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "delivery": {
    "path": "/content-browser/item/delivery.glb",
    "thumbnail": "/content-browser/item/thumbnails/delivery.png",
    "displayName": "Delivery",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "deliveryFlat": {
    "path": "/content-browser/item/deliveryFlat.glb",
    "thumbnail": "/content-browser/item/thumbnails/deliveryFlat.png",
    "displayName": "DeliveryFlat",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "detail_awning": {
    "path": "/content-browser/item/detail_awning.glb",
    "thumbnail": "/content-browser/item/thumbnails/detail_awning.png",
    "displayName": "Detail Awning",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "detail_awningWide": {
    "path": "/content-browser/item/detail_awningWide.glb",
    "thumbnail": "/content-browser/item/thumbnails/detail_awningWide.png",
    "displayName": "Detail AwningWide",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "detail_overhang": {
    "path": "/content-browser/item/detail_overhang.glb",
    "thumbnail": "/content-browser/item/thumbnails/detail_overhang.png",
    "displayName": "Detail Overhang",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "detail_overhangWide": {
    "path": "/content-browser/item/detail_overhangWide.glb",
    "thumbnail": "/content-browser/item/thumbnails/detail_overhangWide.png",
    "displayName": "Detail OverhangWide",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "detail_umbrella": {
    "path": "/content-browser/item/detail_umbrella.glb",
    "thumbnail": "/content-browser/item/thumbnails/detail_umbrella.png",
    "displayName": "Detail Umbrella",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "detail_umbrellaDetailed": {
    "path": "/content-browser/item/detail_umbrellaDetailed.glb",
    "thumbnail": "/content-browser/item/thumbnails/detail_umbrellaDetailed.png",
    "displayName": "Detail UmbrellaDetailed",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "firetruck": {
    "path": "/content-browser/item/firetruck.glb",
    "thumbnail": "/content-browser/item/thumbnails/firetruck.png",
    "displayName": "Firetruck",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "hatchbackSports": {
    "path": "/content-browser/item/hatchbackSports.glb",
    "thumbnail": "/content-browser/item/thumbnails/hatchbackSports.png",
    "displayName": "HatchbackSports",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "police": {
    "path": "/content-browser/item/police.glb",
    "thumbnail": "/content-browser/item/thumbnails/police.png",
    "displayName": "Police",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "race": {
    "path": "/content-browser/item/race.glb",
    "thumbnail": "/content-browser/item/thumbnails/race.png",
    "displayName": "Race",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "raceFuture": {
    "path": "/content-browser/item/raceFuture.glb",
    "thumbnail": "/content-browser/item/thumbnails/raceFuture.png",
    "displayName": "RaceFuture",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "sedan": {
    "path": "/content-browser/item/sedan.glb",
    "thumbnail": "/content-browser/item/thumbnails/sedan.png",
    "displayName": "Sedan",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "sedanSports": {
    "path": "/content-browser/item/sedanSports.glb",
    "thumbnail": "/content-browser/item/thumbnails/sedanSports.png",
    "displayName": "SedanSports",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "suv": {
    "path": "/content-browser/item/suv.glb",
    "thumbnail": "/content-browser/item/thumbnails/suv.png",
    "displayName": "Suv",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "suvLuxury": {
    "path": "/content-browser/item/suvLuxury.glb",
    "thumbnail": "/content-browser/item/thumbnails/suvLuxury.png",
    "displayName": "SuvLuxury",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "taxi": {
    "path": "/content-browser/item/taxi.glb",
    "thumbnail": "/content-browser/item/thumbnails/taxi.png",
    "displayName": "Taxi",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "tractor": {
    "path": "/content-browser/item/tractor.glb",
    "thumbnail": "/content-browser/item/thumbnails/tractor.png",
    "displayName": "Tractor",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "tractorPolice": {
    "path": "/content-browser/item/tractorPolice.glb",
    "thumbnail": "/content-browser/item/thumbnails/tractorPolice.png",
    "displayName": "TractorPolice",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "tractorShovel": {
    "path": "/content-browser/item/tractorShovel.glb",
    "thumbnail": "/content-browser/item/thumbnails/tractorShovel.png",
    "displayName": "TractorShovel",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "tree_large": {
    "path": "/content-browser/item/tree_large.glb",
    "thumbnail": "/content-browser/item/thumbnails/tree_large.png",
    "displayName": "Tree Large",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "tree_small": {
    "path": "/content-browser/item/tree_small.glb",
    "thumbnail": "/content-browser/item/thumbnails/tree_small.png",
    "displayName": "Tree Small",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "truck": {
    "path": "/content-browser/item/truck.glb",
    "thumbnail": "/content-browser/item/thumbnails/truck.png",
    "displayName": "Truck",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "truckFlat": {
    "path": "/content-browser/item/truckFlat.glb",
    "thumbnail": "/content-browser/item/thumbnails/truckFlat.png",
    "displayName": "TruckFlat",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "van": {
    "path": "/content-browser/item/van.glb",
    "thumbnail": "/content-browser/item/thumbnails/van.png",
    "displayName": "Van",
    "type": "model",
    "modelType": "item",
    "hudModelRot": [
      0,
      0,
      0
    ]
  }
};
		
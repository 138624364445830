
// Generated by generateContentBrowserItems.js

/* eslint-disable import/no-anonymous-default-export */
export default {
  "house_type01": {
    "path": "/content-browser/city/house_type01.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type01.png",
    "displayName": "House Type01",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type02": {
    "path": "/content-browser/city/house_type02.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type02.png",
    "displayName": "House Type02",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type03": {
    "path": "/content-browser/city/house_type03.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type03.png",
    "displayName": "House Type03",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type04": {
    "path": "/content-browser/city/house_type04.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type04.png",
    "displayName": "House Type04",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type05": {
    "path": "/content-browser/city/house_type05.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type05.png",
    "displayName": "House Type05",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type06": {
    "path": "/content-browser/city/house_type06.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type06.png",
    "displayName": "House Type06",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type07": {
    "path": "/content-browser/city/house_type07.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type07.png",
    "displayName": "House Type07",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type08": {
    "path": "/content-browser/city/house_type08.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type08.png",
    "displayName": "House Type08",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type09": {
    "path": "/content-browser/city/house_type09.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type09.png",
    "displayName": "House Type09",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type10": {
    "path": "/content-browser/city/house_type10.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type10.png",
    "displayName": "House Type10",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type11": {
    "path": "/content-browser/city/house_type11.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type11.png",
    "displayName": "House Type11",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type12": {
    "path": "/content-browser/city/house_type12.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type12.png",
    "displayName": "House Type12",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type13": {
    "path": "/content-browser/city/house_type13.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type13.png",
    "displayName": "House Type13",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type14": {
    "path": "/content-browser/city/house_type14.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type14.png",
    "displayName": "House Type14",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type15": {
    "path": "/content-browser/city/house_type15.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type15.png",
    "displayName": "House Type15",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type16": {
    "path": "/content-browser/city/house_type16.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type16.png",
    "displayName": "House Type16",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type17": {
    "path": "/content-browser/city/house_type17.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type17.png",
    "displayName": "House Type17",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type18": {
    "path": "/content-browser/city/house_type18.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type18.png",
    "displayName": "House Type18",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type19": {
    "path": "/content-browser/city/house_type19.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type19.png",
    "displayName": "House Type19",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type20": {
    "path": "/content-browser/city/house_type20.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type20.png",
    "displayName": "House Type20",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "house_type21": {
    "path": "/content-browser/city/house_type21.glb",
    "thumbnail": "/content-browser/city/thumbnails/house_type21.png",
    "displayName": "House Type21",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "large_buildingA": {
    "path": "/content-browser/city/large_buildingA.glb",
    "thumbnail": "/content-browser/city/thumbnails/large_buildingA.png",
    "displayName": "Large BuildingA",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "large_buildingB": {
    "path": "/content-browser/city/large_buildingB.glb",
    "thumbnail": "/content-browser/city/thumbnails/large_buildingB.png",
    "displayName": "Large BuildingB",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "large_buildingC": {
    "path": "/content-browser/city/large_buildingC.glb",
    "thumbnail": "/content-browser/city/thumbnails/large_buildingC.png",
    "displayName": "Large BuildingC",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "large_buildingD": {
    "path": "/content-browser/city/large_buildingD.glb",
    "thumbnail": "/content-browser/city/thumbnails/large_buildingD.png",
    "displayName": "Large BuildingD",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "large_buildingE": {
    "path": "/content-browser/city/large_buildingE.glb",
    "thumbnail": "/content-browser/city/thumbnails/large_buildingE.png",
    "displayName": "Large BuildingE",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "large_buildingF": {
    "path": "/content-browser/city/large_buildingF.glb",
    "thumbnail": "/content-browser/city/thumbnails/large_buildingF.png",
    "displayName": "Large BuildingF",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "large_buildingG": {
    "path": "/content-browser/city/large_buildingG.glb",
    "thumbnail": "/content-browser/city/thumbnails/large_buildingG.png",
    "displayName": "Large BuildingG",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingA": {
    "path": "/content-browser/city/low_buildingA.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingA.png",
    "displayName": "Low BuildingA",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingB": {
    "path": "/content-browser/city/low_buildingB.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingB.png",
    "displayName": "Low BuildingB",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingC": {
    "path": "/content-browser/city/low_buildingC.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingC.png",
    "displayName": "Low BuildingC",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingD": {
    "path": "/content-browser/city/low_buildingD.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingD.png",
    "displayName": "Low BuildingD",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingE": {
    "path": "/content-browser/city/low_buildingE.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingE.png",
    "displayName": "Low BuildingE",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingF": {
    "path": "/content-browser/city/low_buildingF.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingF.png",
    "displayName": "Low BuildingF",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingG": {
    "path": "/content-browser/city/low_buildingG.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingG.png",
    "displayName": "Low BuildingG",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingH": {
    "path": "/content-browser/city/low_buildingH.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingH.png",
    "displayName": "Low BuildingH",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingI": {
    "path": "/content-browser/city/low_buildingI.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingI.png",
    "displayName": "Low BuildingI",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingJ": {
    "path": "/content-browser/city/low_buildingJ.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingJ.png",
    "displayName": "Low BuildingJ",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingK": {
    "path": "/content-browser/city/low_buildingK.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingK.png",
    "displayName": "Low BuildingK",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingL": {
    "path": "/content-browser/city/low_buildingL.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingL.png",
    "displayName": "Low BuildingL",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingM": {
    "path": "/content-browser/city/low_buildingM.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingM.png",
    "displayName": "Low BuildingM",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_buildingN": {
    "path": "/content-browser/city/low_buildingN.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_buildingN.png",
    "displayName": "Low BuildingN",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_wideA": {
    "path": "/content-browser/city/low_wideA.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_wideA.png",
    "displayName": "Low WideA",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "low_wideB": {
    "path": "/content-browser/city/low_wideB.glb",
    "thumbnail": "/content-browser/city/thumbnails/low_wideB.png",
    "displayName": "Low WideB",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "skyscraperA": {
    "path": "/content-browser/city/skyscraperA.glb",
    "thumbnail": "/content-browser/city/thumbnails/skyscraperA.png",
    "displayName": "SkyscraperA",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "skyscraperB": {
    "path": "/content-browser/city/skyscraperB.glb",
    "thumbnail": "/content-browser/city/thumbnails/skyscraperB.png",
    "displayName": "SkyscraperB",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "skyscraperC": {
    "path": "/content-browser/city/skyscraperC.glb",
    "thumbnail": "/content-browser/city/thumbnails/skyscraperC.png",
    "displayName": "SkyscraperC",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "skyscraperD": {
    "path": "/content-browser/city/skyscraperD.glb",
    "thumbnail": "/content-browser/city/thumbnails/skyscraperD.png",
    "displayName": "SkyscraperD",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "skyscraperE": {
    "path": "/content-browser/city/skyscraperE.glb",
    "thumbnail": "/content-browser/city/thumbnails/skyscraperE.png",
    "displayName": "SkyscraperE",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "skyscraperF": {
    "path": "/content-browser/city/skyscraperF.glb",
    "thumbnail": "/content-browser/city/thumbnails/skyscraperF.png",
    "displayName": "SkyscraperF",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "small_buildingA": {
    "path": "/content-browser/city/small_buildingA.glb",
    "thumbnail": "/content-browser/city/thumbnails/small_buildingA.png",
    "displayName": "Small BuildingA",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "small_buildingB": {
    "path": "/content-browser/city/small_buildingB.glb",
    "thumbnail": "/content-browser/city/thumbnails/small_buildingB.png",
    "displayName": "Small BuildingB",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "small_buildingC": {
    "path": "/content-browser/city/small_buildingC.glb",
    "thumbnail": "/content-browser/city/thumbnails/small_buildingC.png",
    "displayName": "Small BuildingC",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "small_buildingD": {
    "path": "/content-browser/city/small_buildingD.glb",
    "thumbnail": "/content-browser/city/thumbnails/small_buildingD.png",
    "displayName": "Small BuildingD",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "small_buildingE": {
    "path": "/content-browser/city/small_buildingE.glb",
    "thumbnail": "/content-browser/city/thumbnails/small_buildingE.png",
    "displayName": "Small BuildingE",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "small_buildingF": {
    "path": "/content-browser/city/small_buildingF.glb",
    "thumbnail": "/content-browser/city/thumbnails/small_buildingF.png",
    "displayName": "Small BuildingF",
    "type": "model",
    "modelType": "city",
    "hudModelRot": [
      0,
      0,
      0
    ]
  }
};
		
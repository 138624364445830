
// Generated by generateContentBrowserItems.js

/* eslint-disable import/no-anonymous-default-export */
export default {
  "bridge_pillar": {
    "path": "/content-browser/floor/bridge_pillar.glb",
    "thumbnail": "/content-browser/floor/thumbnails/bridge_pillar.png",
    "displayName": "Bridge Pillar",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "bridge_pillarWide": {
    "path": "/content-browser/floor/bridge_pillarWide.glb",
    "thumbnail": "/content-browser/floor/thumbnails/bridge_pillarWide.png",
    "displayName": "Bridge PillarWide",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "construction_barrier": {
    "path": "/content-browser/floor/construction_barrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/construction_barrier.png",
    "displayName": "Construction Barrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "construction_light": {
    "path": "/content-browser/floor/construction_light.glb",
    "thumbnail": "/content-browser/floor/thumbnails/construction_light.png",
    "displayName": "Construction Light",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "construction_pylon": {
    "path": "/content-browser/floor/construction_pylon.glb",
    "thumbnail": "/content-browser/floor/thumbnails/construction_pylon.png",
    "displayName": "Construction Pylon",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "light_curved": {
    "path": "/content-browser/floor/light_curved.glb",
    "thumbnail": "/content-browser/floor/thumbnails/light_curved.png",
    "displayName": "Light Curved",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "light_curvedCross": {
    "path": "/content-browser/floor/light_curvedCross.glb",
    "thumbnail": "/content-browser/floor/thumbnails/light_curvedCross.png",
    "displayName": "Light CurvedCross",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "light_curvedDouble": {
    "path": "/content-browser/floor/light_curvedDouble.glb",
    "thumbnail": "/content-browser/floor/thumbnails/light_curvedDouble.png",
    "displayName": "Light CurvedDouble",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "light_square": {
    "path": "/content-browser/floor/light_square.glb",
    "thumbnail": "/content-browser/floor/thumbnails/light_square.png",
    "displayName": "Light Square",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "light_squareCross": {
    "path": "/content-browser/floor/light_squareCross.glb",
    "thumbnail": "/content-browser/floor/thumbnails/light_squareCross.png",
    "displayName": "Light SquareCross",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "light_squareDouble": {
    "path": "/content-browser/floor/light_squareDouble.glb",
    "thumbnail": "/content-browser/floor/thumbnails/light_squareDouble.png",
    "displayName": "Light SquareDouble",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_bend": {
    "path": "/content-browser/floor/road_bend.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_bend.png",
    "displayName": "Road Bend",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_bendBarrier": {
    "path": "/content-browser/floor/road_bendBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_bendBarrier.png",
    "displayName": "Road BendBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_bendSidewalk": {
    "path": "/content-browser/floor/road_bendSidewalk.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_bendSidewalk.png",
    "displayName": "Road BendSidewalk",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_bendSquare": {
    "path": "/content-browser/floor/road_bendSquare.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_bendSquare.png",
    "displayName": "Road BendSquare",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_bendSquareBarrier": {
    "path": "/content-browser/floor/road_bendSquareBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_bendSquareBarrier.png",
    "displayName": "Road BendSquareBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_bridge": {
    "path": "/content-browser/floor/road_bridge.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_bridge.png",
    "displayName": "Road Bridge",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_crossing": {
    "path": "/content-browser/floor/road_crossing.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_crossing.png",
    "displayName": "Road Crossing",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_crossroad": {
    "path": "/content-browser/floor/road_crossroad.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_crossroad.png",
    "displayName": "Road Crossroad",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_crossroadBarrier": {
    "path": "/content-browser/floor/road_crossroadBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_crossroadBarrier.png",
    "displayName": "Road CrossroadBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_crossroadLine": {
    "path": "/content-browser/floor/road_crossroadLine.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_crossroadLine.png",
    "displayName": "Road CrossroadLine",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_crossroadPath": {
    "path": "/content-browser/floor/road_crossroadPath.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_crossroadPath.png",
    "displayName": "Road CrossroadPath",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_curve": {
    "path": "/content-browser/floor/road_curve.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_curve.png",
    "displayName": "Road Curve",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_curveBarrier": {
    "path": "/content-browser/floor/road_curveBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_curveBarrier.png",
    "displayName": "Road CurveBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_curveIntersection": {
    "path": "/content-browser/floor/road_curveIntersection.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_curveIntersection.png",
    "displayName": "Road CurveIntersection",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_curveIntersectionBarrier": {
    "path": "/content-browser/floor/road_curveIntersectionBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_curveIntersectionBarrier.png",
    "displayName": "Road CurveIntersectionBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_curvePavement": {
    "path": "/content-browser/floor/road_curvePavement.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_curvePavement.png",
    "displayName": "Road CurvePavement",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_drivewayDouble": {
    "path": "/content-browser/floor/road_drivewayDouble.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_drivewayDouble.png",
    "displayName": "Road DrivewayDouble",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_drivewayDoubleBarrier": {
    "path": "/content-browser/floor/road_drivewayDoubleBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_drivewayDoubleBarrier.png",
    "displayName": "Road DrivewayDoubleBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_drivewaySingle": {
    "path": "/content-browser/floor/road_drivewaySingle.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_drivewaySingle.png",
    "displayName": "Road DrivewaySingle",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_drivewaySingleBarrier": {
    "path": "/content-browser/floor/road_drivewaySingleBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_drivewaySingleBarrier.png",
    "displayName": "Road DrivewaySingleBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_end": {
    "path": "/content-browser/floor/road_end.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_end.png",
    "displayName": "Road End",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_endBarrier": {
    "path": "/content-browser/floor/road_endBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_endBarrier.png",
    "displayName": "Road EndBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_endRound": {
    "path": "/content-browser/floor/road_endRound.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_endRound.png",
    "displayName": "Road EndRound",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_endRoundBarrier": {
    "path": "/content-browser/floor/road_endRoundBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_endRoundBarrier.png",
    "displayName": "Road EndRoundBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_intersection": {
    "path": "/content-browser/floor/road_intersection.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_intersection.png",
    "displayName": "Road Intersection",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_intersectionBarrier": {
    "path": "/content-browser/floor/road_intersectionBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_intersectionBarrier.png",
    "displayName": "Road IntersectionBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_intersectionLine": {
    "path": "/content-browser/floor/road_intersectionLine.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_intersectionLine.png",
    "displayName": "Road IntersectionLine",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_intersectionPath": {
    "path": "/content-browser/floor/road_intersectionPath.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_intersectionPath.png",
    "displayName": "Road IntersectionPath",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_roundabout": {
    "path": "/content-browser/floor/road_roundabout.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_roundabout.png",
    "displayName": "Road Roundabout",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_roundaboutBarrier": {
    "path": "/content-browser/floor/road_roundaboutBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_roundaboutBarrier.png",
    "displayName": "Road RoundaboutBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_side": {
    "path": "/content-browser/floor/road_side.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_side.png",
    "displayName": "Road Side",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_sideBarrier": {
    "path": "/content-browser/floor/road_sideBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_sideBarrier.png",
    "displayName": "Road SideBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_sideEntry": {
    "path": "/content-browser/floor/road_sideEntry.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_sideEntry.png",
    "displayName": "Road SideEntry",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_sideEntryBarrier": {
    "path": "/content-browser/floor/road_sideEntryBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_sideEntryBarrier.png",
    "displayName": "Road SideEntryBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_sideExit": {
    "path": "/content-browser/floor/road_sideExit.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_sideExit.png",
    "displayName": "Road SideExit",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_sideExitBarrier": {
    "path": "/content-browser/floor/road_sideExitBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_sideExitBarrier.png",
    "displayName": "Road SideExitBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_slant": {
    "path": "/content-browser/floor/road_slant.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_slant.png",
    "displayName": "Road Slant",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_slantBarrier": {
    "path": "/content-browser/floor/road_slantBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_slantBarrier.png",
    "displayName": "Road SlantBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_slantCurve": {
    "path": "/content-browser/floor/road_slantCurve.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_slantCurve.png",
    "displayName": "Road SlantCurve",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_slantCurveBarrier": {
    "path": "/content-browser/floor/road_slantCurveBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_slantCurveBarrier.png",
    "displayName": "Road SlantCurveBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_slantFlat": {
    "path": "/content-browser/floor/road_slantFlat.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_slantFlat.png",
    "displayName": "Road SlantFlat",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_slantFlatCurve": {
    "path": "/content-browser/floor/road_slantFlatCurve.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_slantFlatCurve.png",
    "displayName": "Road SlantFlatCurve",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_slantFlatHigh": {
    "path": "/content-browser/floor/road_slantFlatHigh.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_slantFlatHigh.png",
    "displayName": "Road SlantFlatHigh",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_slantHigh": {
    "path": "/content-browser/floor/road_slantHigh.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_slantHigh.png",
    "displayName": "Road SlantHigh",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_slantHighBarrier": {
    "path": "/content-browser/floor/road_slantHighBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_slantHighBarrier.png",
    "displayName": "Road SlantHighBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_split": {
    "path": "/content-browser/floor/road_split.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_split.png",
    "displayName": "Road Split",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_splitBarrier": {
    "path": "/content-browser/floor/road_splitBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_splitBarrier.png",
    "displayName": "Road SplitBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_square": {
    "path": "/content-browser/floor/road_square.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_square.png",
    "displayName": "Road Square",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_squareBarrier": {
    "path": "/content-browser/floor/road_squareBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_squareBarrier.png",
    "displayName": "Road SquareBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_straight": {
    "path": "/content-browser/floor/road_straight.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_straight.png",
    "displayName": "Road Straight",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_straightBarrier": {
    "path": "/content-browser/floor/road_straightBarrier.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_straightBarrier.png",
    "displayName": "Road StraightBarrier",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "road_straightBarrierEnd": {
    "path": "/content-browser/floor/road_straightBarrierEnd.glb",
    "thumbnail": "/content-browser/floor/thumbnails/road_straightBarrierEnd.png",
    "displayName": "Road StraightBarrierEnd",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "tile_high": {
    "path": "/content-browser/floor/tile_high.glb",
    "thumbnail": "/content-browser/floor/thumbnails/tile_high.png",
    "displayName": "Tile High",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "tile_low": {
    "path": "/content-browser/floor/tile_low.glb",
    "thumbnail": "/content-browser/floor/thumbnails/tile_low.png",
    "displayName": "Tile Low",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "tile_slant": {
    "path": "/content-browser/floor/tile_slant.glb",
    "thumbnail": "/content-browser/floor/thumbnails/tile_slant.png",
    "displayName": "Tile Slant",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "tile_slantHigh": {
    "path": "/content-browser/floor/tile_slantHigh.glb",
    "thumbnail": "/content-browser/floor/thumbnails/tile_slantHigh.png",
    "displayName": "Tile SlantHigh",
    "type": "model",
    "modelType": "floor",
    "hudModelRot": [
      0,
      0,
      0
    ]
  }
};
		
import { useThree } from '@react-three/fiber'
import { useGlobalState } from '../../GlobalState'
import * as THREE from 'three'

export default function RaycasterObjects() {
	const selected = useGlobalState((state) => state.sceneNoPersist.selected)
	const { scene } = useThree()

	let raycasterObjectsArr: THREE.Object3D[] = []

	scene.traverse((child) => {
		// Only include "ground" objects or created objects

		// Explanation of (child.userData.moveableObj === true && child.name !== selected.name)
		// "child.userData.moveableObj === true" allows you to stack raycasterObjects
		// and "child.name !== selected.name" makes sure the raycaster doesn't hit the currently selected object to avoid infinite interactions
		if (child.userData.staticObj === true || (child.userData.moveableObj === true && child.name !== selected.name)) {
			raycasterObjectsArr.push(child)
		}
	})

	return raycasterObjectsArr
}

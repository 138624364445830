
// Generated by generateContentBrowserItems.js

/* eslint-disable import/no-anonymous-default-export */
export default {
  "Roof_Inner_Corner_Green_01": {
    "path": "/content-browser/roof/Roof_Inner_Corner_Green_01.glb",
    "thumbnail": "/content-browser/roof/thumbnails/Roof_Inner_Corner_Green_01.png",
    "displayName": "Roof Inner Corner Green 01",
    "type": "model",
    "modelType": "roof",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Roof_Inner_Corner_Red_01": {
    "path": "/content-browser/roof/Roof_Inner_Corner_Red_01.glb",
    "thumbnail": "/content-browser/roof/thumbnails/Roof_Inner_Corner_Red_01.png",
    "displayName": "Roof Inner Corner Red 01",
    "type": "model",
    "modelType": "roof",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Roof_Point_Green_01": {
    "path": "/content-browser/roof/Roof_Point_Green_01.glb",
    "thumbnail": "/content-browser/roof/thumbnails/Roof_Point_Green_01.png",
    "displayName": "Roof Point Green 01",
    "type": "model",
    "modelType": "roof",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Roof_Point_Red_01": {
    "path": "/content-browser/roof/Roof_Point_Red_01.glb",
    "thumbnail": "/content-browser/roof/thumbnails/Roof_Point_Red_01.png",
    "displayName": "Roof Point Red 01",
    "type": "model",
    "modelType": "roof",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Roof_Slant_Green_01": {
    "path": "/content-browser/roof/Roof_Slant_Green_01.glb",
    "thumbnail": "/content-browser/roof/thumbnails/Roof_Slant_Green_01.png",
    "displayName": "Roof Slant Green 01",
    "type": "model",
    "modelType": "roof",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Roof_Slant_Red_01": {
    "path": "/content-browser/roof/Roof_Slant_Red_01.glb",
    "thumbnail": "/content-browser/roof/thumbnails/Roof_Slant_Red_01.png",
    "displayName": "Roof Slant Red 01",
    "type": "model",
    "modelType": "roof",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Roof_Straight_Green_01": {
    "path": "/content-browser/roof/Roof_Straight_Green_01.glb",
    "thumbnail": "/content-browser/roof/thumbnails/Roof_Straight_Green_01.png",
    "displayName": "Roof Straight Green 01",
    "type": "model",
    "modelType": "roof",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Roof_Straight_Red_01": {
    "path": "/content-browser/roof/Roof_Straight_Red_01.glb",
    "thumbnail": "/content-browser/roof/thumbnails/Roof_Straight_Red_01.png",
    "displayName": "Roof Straight Red 01",
    "type": "model",
    "modelType": "roof",
    "hudModelRot": [
      0,
      0,
      0
    ]
  }
};
		
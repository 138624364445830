
// Generated by generateContentBrowserItems.js

/* eslint-disable import/no-anonymous-default-export */
export default {
  "Castle_Wall_01": {
    "path": "/content-browser/wall/Castle_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Castle_Wall_01.png",
    "displayName": "Castle Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Arch_01": {
    "path": "/content-browser/wall/Grey_Arch_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Arch_01.png",
    "displayName": "Grey Arch 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Border_Wall_01": {
    "path": "/content-browser/wall/Grey_Border_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Border_Wall_01.png",
    "displayName": "Grey Border Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Broken_Wall_01": {
    "path": "/content-browser/wall/Grey_Broken_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Broken_Wall_01.png",
    "displayName": "Grey Broken Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Corner_01": {
    "path": "/content-browser/wall/Grey_Corner_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Corner_01.png",
    "displayName": "Grey Corner 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Door_Round_01": {
    "path": "/content-browser/wall/Grey_Door_Round_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Door_Round_01.png",
    "displayName": "Grey Door Round 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Door_Square_01": {
    "path": "/content-browser/wall/Grey_Door_Square_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Door_Square_01.png",
    "displayName": "Grey Door Square 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Pole_01": {
    "path": "/content-browser/wall/Grey_Pole_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Pole_01.png",
    "displayName": "Grey Pole 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Short_Wall_01": {
    "path": "/content-browser/wall/Grey_Short_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Short_Wall_01.png",
    "displayName": "Grey Short Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Slanted_Pole_01": {
    "path": "/content-browser/wall/Grey_Slanted_Pole_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Slanted_Pole_01.png",
    "displayName": "Grey Slanted Pole 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Slanted_Wall_01": {
    "path": "/content-browser/wall/Grey_Slanted_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Slanted_Wall_01.png",
    "displayName": "Grey Slanted Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Small_Wall_01": {
    "path": "/content-browser/wall/Grey_Small_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Small_Wall_01.png",
    "displayName": "Grey Small Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Triangle_01": {
    "path": "/content-browser/wall/Grey_Triangle_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Triangle_01.png",
    "displayName": "Grey Triangle 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Window_Narrow_01": {
    "path": "/content-browser/wall/Grey_Window_Narrow_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Window_Narrow_01.png",
    "displayName": "Grey Window Narrow 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Window_Round_01": {
    "path": "/content-browser/wall/Grey_Window_Round_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Window_Round_01.png",
    "displayName": "Grey Window Round 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Window_Round_Long_01": {
    "path": "/content-browser/wall/Grey_Window_Round_Long_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Window_Round_Long_01.png",
    "displayName": "Grey Window Round Long 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Window_Square_01": {
    "path": "/content-browser/wall/Grey_Window_Square_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Window_Square_01.png",
    "displayName": "Grey Window Square 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Grey_Window_Square_Sill_01": {
    "path": "/content-browser/wall/Grey_Window_Square_Sill_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Grey_Window_Square_Sill_01.png",
    "displayName": "Grey Window Square Sill 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Iron_Door_01": {
    "path": "/content-browser/wall/Iron_Door_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Iron_Door_01.png",
    "displayName": "Iron Door 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Arch_01": {
    "path": "/content-browser/wall/Wood_Arch_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Arch_01.png",
    "displayName": "Wood Arch 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Border_Wall_01": {
    "path": "/content-browser/wall/Wood_Border_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Border_Wall_01.png",
    "displayName": "Wood Border Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Broken_Wall_01": {
    "path": "/content-browser/wall/Wood_Broken_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Broken_Wall_01.png",
    "displayName": "Wood Broken Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Corner_01": {
    "path": "/content-browser/wall/Wood_Corner_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Corner_01.png",
    "displayName": "Wood Corner 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Door_01": {
    "path": "/content-browser/wall/Wood_Door_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Door_01.png",
    "displayName": "Wood Door 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Door_Round_01": {
    "path": "/content-browser/wall/Wood_Door_Round_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Door_Round_01.png",
    "displayName": "Wood Door Round 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Door_Square_01": {
    "path": "/content-browser/wall/Wood_Door_Square_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Door_Square_01.png",
    "displayName": "Wood Door Square 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Pole_01": {
    "path": "/content-browser/wall/Wood_Pole_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Pole_01.png",
    "displayName": "Wood Pole 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Slanted_Pole_01": {
    "path": "/content-browser/wall/Wood_Slanted_Pole_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Slanted_Pole_01.png",
    "displayName": "Wood Slanted Pole 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Slanted_Wall_01": {
    "path": "/content-browser/wall/Wood_Slanted_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Slanted_Wall_01.png",
    "displayName": "Wood Slanted Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Small_Wall_01": {
    "path": "/content-browser/wall/Wood_Small_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Small_Wall_01.png",
    "displayName": "Wood Small Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Tiny_Wall_01": {
    "path": "/content-browser/wall/Wood_Tiny_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Tiny_Wall_01.png",
    "displayName": "Wood Tiny Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Wall_01": {
    "path": "/content-browser/wall/Wood_Wall_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Wall_01.png",
    "displayName": "Wood Wall 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Wall_Cross_01": {
    "path": "/content-browser/wall/Wood_Wall_Cross_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Wall_Cross_01.png",
    "displayName": "Wood Wall Cross 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Wall_Double_Cross_01": {
    "path": "/content-browser/wall/Wood_Wall_Double_Cross_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Wall_Double_Cross_01.png",
    "displayName": "Wood Wall Double Cross 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Window_Narrow_01": {
    "path": "/content-browser/wall/Wood_Window_Narrow_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Window_Narrow_01.png",
    "displayName": "Wood Window Narrow 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Window_Round_01": {
    "path": "/content-browser/wall/Wood_Window_Round_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Window_Round_01.png",
    "displayName": "Wood Window Round 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Window_Round_Long_01": {
    "path": "/content-browser/wall/Wood_Window_Round_Long_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Window_Round_Long_01.png",
    "displayName": "Wood Window Round Long 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Window_Round_Sill_01": {
    "path": "/content-browser/wall/Wood_Window_Round_Sill_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Window_Round_Sill_01.png",
    "displayName": "Wood Window Round Sill 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Window_Square_01": {
    "path": "/content-browser/wall/Wood_Window_Square_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Window_Square_01.png",
    "displayName": "Wood Window Square 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  },
  "Wood_Window_Square_Sill_01": {
    "path": "/content-browser/wall/Wood_Window_Square_Sill_01.glb",
    "thumbnail": "/content-browser/wall/thumbnails/Wood_Window_Square_Sill_01.png",
    "displayName": "Wood Window Square Sill 01",
    "type": "model",
    "modelType": "wall",
    "hudModelRot": [
      0,
      0,
      0
    ]
  }
};
		